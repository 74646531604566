import React, { useContext, useEffect, useState } from "react";
import { SEOReactHelmet } from "../../../components/SEO/SEOReactHelmet";
import { useStateWithLocalStorage } from "../../../hooks/useStateWithLocalStorage/useStateWithLocalStorage";
import { OnboardingGenderPage } from "../../onboarding/pages";
import { SignUpPage, SignUpStep } from "../../../pages";
import { useNavigate } from "../../../components/Link/Link";
import { LoginContext } from "../../../components/LoginProvider/LoginProvider";
import PreviewMealPlanCard from "../../../components/MealPlanCard/PreviewMealPlanCard/PreviewMealPlanCard";
import { pageContext } from "../../../components/PageProvider/PageProvider";
import { GenerateMealPlan_generateMealplan } from "../../../types/generateMealplan";
import { GreenStar } from "../components/ThreeGoalsList";
import { PersonalizedPlanNavbar } from "../components/PersonalizedPlanNavbar";
import { intervalToDuration, addMinutes, subSeconds, isEqual } from "date-fns";
import { StaticImage } from "gatsby-plugin-image";

const PersonalizedPlanSalesPage = () => {
  const { locale } = useContext(pageContext);

  const { loading: isUserLoading } = useContext(LoginContext);

  const navigate = useNavigate();

  const [anonMealPlan] = useStateWithLocalStorage<string | undefined>(
    "dd/onboarding/anonymous-meal-plan",
    undefined
  );

  useEffect(() => {
    if (!isUserLoading) {
      if (!anonMealPlan) {
        navigate({
          to: OnboardingGenderPage,
        });
      }
    }
  }, [anonMealPlan, isUserLoading, navigate]);

  const [duration, setDuration] = useState<Duration>({ minutes: 15, seconds: 0 });

  useEffect(() => {
    const start = new Date();
    let end = addMinutes(new Date(), 15);
    const interval = setInterval(() => {
      if (!isEqual(start, end)) {
        end = subSeconds(end, 1);
        const currentDuration = intervalToDuration({
          start: start,
          end: end,
        });
        setDuration(currentDuration);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <SEOReactHelmet noindex title="Diet Doctor - Meal Plan Sales" lang={locale as string} />

      {/* Navbar */}
      <PersonalizedPlanNavbar />

      {/* Success state */}
      {typeof anonMealPlan !== "undefined" && (
        <main>
          {/* Heading */}
          <section className="bg-blue-500 py-6 px-3 text-left sm:text-center">
            <h2 className="text-2xl text-[1.6rem] text-white font-medium">Start your 7-day free trial</h2>
          </section>

          {/* Goals */}
          <section className="flex flex-col items-center text-center max-w-7xl mx-auto px-4 mt-8">
            <ul className="list-none text-left text-lg max-w-xs sm:max-w-sm p-0 m-0">
              <li className="flex flex-row items-start space-x-2">
                <GreenStar />
                <span>Reach your healthy weight</span>
              </li>

              <li className="flex flex-row items-start space-x-2 mt-2">
                <GreenStar />
                <span>Create healthy habits to maintain your results long term</span>
              </li>
              <li className="flex flex-row items-start space-x-2 mt-2">
                <GreenStar />
                <span>Find which foods work for you</span>
              </li>
            </ul>
          </section>

          {/* FOMO */}
          <section className="max-w-md sm:max-w-xl mt-8 mx-auto flex flex-col items-center text-center">
            <div className="bg-blue-200 rounded-lg mx-4 p-6">
              <h3 className="text-base uppercase -mx-3">Personalized meal plan reserved</h3>
              <p className="max-w-sm sm:max-w-xl mx-auto text-base px-4">
                Your personalized meal plan will be saved for the next 15 minutes.
              </p>
              <p className="text-base font-medium mt-2">TIME REMAINING:</p>
              <span className="text-lg inline-block -mt-2">
                {duration.minutes?.toLocaleString(undefined, { minimumIntegerDigits: 2 })}:
                {duration.seconds?.toLocaleString(undefined, { minimumIntegerDigits: 2 })}
              </span>
            </div>
          </section>

          {/* Meal plan preview */}
          <section className="mt-4 max-w-4xl mx-auto flex flex-col items-center text-center">
            <div className="p-4 my-4 mx-8 border-4 border-blue-200 rounded-lg">
              <PreviewMealPlanCard
                mealPlan={JSON.parse(anonMealPlan) as GenerateMealPlan_generateMealplan}
                errorMessage={"Oops! We can't accommodate all your preferences yet."}
              />
            </div>
          </section>

          {/* How it works */}
          <section className="bg-blue-200 flex-col items-center w-full mx-auto mt-12 pt-12 pb-24">
            <h3 className="text-3xl text-center mx-auto px-3 max-w-xs">How your 7-day free trial works</h3>

            <div className="flex flex-col items-left text-left mt-6 mx-auto max-w-sm px-2">
              <div className="flex flex-row items-center">
                <StaticImage
                  src="../../../../static/images/onboarding-AB-test/check-circle.png"
                  placeholder="none"
                  height={64}
                  objectFit="contain"
                  alt="check"
                  className="shrink-0"
                />
                <div className="flex flex-col ml-3">
                  <h4 className="text-xl line-through text-[#2D5060]">Create your meal plan</h4>
                  <p className="text-lg">You’ve successfully created your personalized meal plan.</p>
                </div>
              </div>

              <div className="border-l-[3px] h-[4.5rem] -my-4 ml-[30.5px] border-[#2D5060]" />

              <div className="flex flex-row items-center">
                <StaticImage
                  src="../../../../static/images/onboarding-AB-test/lock-circle.png"
                  placeholder="none"
                  height={64}
                  objectFit="contain"
                  alt="lock"
                  className="shrink-0"
                />
                <div className="flex flex-col ml-3">
                  <h4 className="text-xl">Activate your free trial</h4>
                  <p className="text-lg">Create your account and choose your membership plan.</p>
                </div>
              </div>

              <div className="border-l-[3px] h-[4.5rem] -mt-4 -mb-5 ml-[30.5px] border-[#C6C6C6]" />

              <div className="flex flex-row items-center">
                <StaticImage
                  src="../../../../static/images/onboarding-AB-test/food-circle.png"
                  placeholder="none"
                  height={64}
                  objectFit="contain"
                  alt="food"
                  className="shrink-0"
                />
                <div className="flex flex-col ml-3">
                  <h4 className="text-xl">Start your journey with us</h4>
                  <p className="text-lg">
                    Cancel at any time in the next 7 days if you feel like it isn’t for you.
                  </p>
                </div>
              </div>
            </div>

            {/* CTA */}
            <div className="-mt-2">
              <ProjectionPageButton />
            </div>
          </section>
        </main>
      )}
    </>
  );
};

// ts-prune-ignore-next
export default PersonalizedPlanSalesPage;

// Misc components
function ProjectionPageButton() {
  const navigate = useNavigate();

  const { user, premium } = useContext(LoginContext);

  const buttonClassNames =
    "block shadow-md hover:shadow-none bg-green px-8 py-4 text-lg mx-auto rounded-lg text-white text-xl hover:underline focus:opacity-95 transition-all font-medium min-w-[335px] mt-12";

  return (
    <>
      {/* The user has no account -> to the create sign up page */}
      {!user && (
        <button
          className={buttonClassNames}
          onClick={() =>
            navigate({
              to: SignUpPage,
              params: {
                step: SignUpStep.ACCOUNT,
                content: "onboarding",
              },
            })
          }
        >
          {"Start 7-day free trial"}
        </button>
      )}

      {/* The user has account but has no premium -> to the payment sign up page */}
      {user && !premium && (
        <button
          className={buttonClassNames}
          onClick={() =>
            navigate({
              to: SignUpPage,
              params: {
                step: SignUpStep.PAYMENT,
                content: "onboarding",
              },
            })
          }
        >
          {"Start 7-day free trial"}
        </button>
      )}
    </>
  );
}
